<template>
  <div ref="container"
       class="absolute h-full px-3 overflow-hidden max-w-[1920px] lg:mx-auto lg:left-0 lg:right-0 lg:top-0 w-[180vw] lg:w-full"
  >
    <div class="absolute inset-0 z-10"
         :style="{ background: 'linear-gradient(360deg, #0A0A0A 10.74%, rgba(10, 10, 10, 0.62465) 61.88%, rgba(10, 10, 10, 0) 100%)' }"
    />
    <div class="grid h-full grid-cols-4 gap-3 px-4 sm:gap-4 lg:gap-8">
      <template v-for="(column, i) in columns"
                :key="i"
      >
        <Presence>
          <Motion v-if="visible"
                  as="div"
                  :initial="{ y: i % 2 === 0 ? animParams.y1start : animParams.y2start, opacity: 0 }"
                  :animate="{ y: i % 2 === 0 ? [animParams.y1start, animParams.y1end] : [animParams.y2start, animParams.y2end], opacity: [0, 1] }"
                  :transition="{
                    duration: 6,
                  }"
                  class="space-y-3 will-change-transform sm:space-y-4 lg:space-y-8"
          >
            <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
            <div v-for="(img, id) in column"
                 :key="id"
                 class="aspect-w-1 aspect-h-1"
            >
              <NuxtImg :src="img.src"
                       alt=""
                       class="object-cover w-full rounded-xl"
                       sizes="xs:33vw sm:19vw md:19vw lg:19vw xl:304px xxl:304px"
                       loading="lazy"
              />
            </div>
          </Motion>
        </Presence>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Motion, Presence } from '@motionone/vue/dist/motion-vue.ssr.js'
import { inView } from 'motion'
import { onMounted, ref, computed } from 'vue'
import { useScreenSize } from '~/composables/use-screen-size'

interface scrollingImages {
  images: {
    src: string
  }[]
}
const props = defineProps<scrollingImages>()

const { isDesktop } = useScreenSize()

const visible = ref(false)
const container = ref()
let animParams

onMounted(() => {
  animParams = {
    y1start: isDesktop.value ? 1000 : 500,
    y1end: isDesktop.value ? -1500 : -500,
    y2start: isDesktop.value ? 1400 : 700,
    y2end: isDesktop.value ? -1000 : -400
  }
  inView(
    container.value,
    () => {
      visible.value = true
    },
    { amount: 0.2 }
  )
})

let imagesToUse = [...props.images]

const chunkArray = (array, chunkSize) => {
  const numberOfChunks = Math.ceil(array.length / chunkSize)

  return [...Array(numberOfChunks)].map((value, index) => {
    return array.slice(index * chunkSize, (index + 1) * chunkSize)
  })
}

const columns = computed(() => {
  return chunkArray(imagesToUse, Math.round(imagesToUse.length / 4))
})

do {
  if (imagesToUse.length > 36) imagesToUse.pop()
  else imagesToUse = imagesToUse.concat(imagesToUse)
} while (imagesToUse.length !== 36)

// shuffle images to use array
for (let i = imagesToUse.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1));
  [imagesToUse[i], imagesToUse[j]] = [imagesToUse[j], imagesToUse[i]]
}
</script>
